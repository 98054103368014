import React from 'react';
import Body from '@sections/Service';
import Layout from '@layouts/business';
import SEO from '@components/seo';

export default () => (
  <Layout>
    <SEO title="Решения | IMstream" />
    <Body />
  </Layout>
);
