import React, { Fragment } from 'react';
import CardRow from './CardRow';
import CTA from '@components/CallToAction';

export default function() {
  return (
    <Fragment>
      <CardRow />
      <CTA />
    </Fragment>
  );
}
